import { SVGProps } from 'react';

const AllEquipmentIconIcon = ({
  height = '20',
  width = '20',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={color}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.689 6h.561A2.25 2.25 0 0 1 4.5 8.25V9c0 .414.336.75.75.75A2.25 2.25 0 0 1 7.5 12v1.5a6.01 6.01 0 0 0 1.5-.189V12a2.25 2.25 0 0 1 2.25-2.25h1.814A5.984 5.984 0 0 0 13.5 7.5c0-.796-.155-1.555-.436-2.25h-.314A.75.75 0 0 0 12 6a2.25 2.25 0 0 1-4.5 0 .75.75 0 0 0-.75-.75h-.375a2.625 2.625 0 0 1-2.619-2.44c-1 .8-1.738 1.913-2.067 3.19ZM4.167.78A7.5 7.5 0 1 0 14.22 4.168v-.002A7.5 7.5 0 0 0 7.5 0a7.47 7.47 0 0 0-3.333.78ZM5.25 1.936v.689c0 .621.504 1.125 1.125 1.125h.375A2.25 2.25 0 0 1 9 6a.75.75 0 0 0 1.5 0 2.25 2.25 0 0 1 1.732-2.19A5.99 5.99 0 0 0 7.5 1.5c-.796 0-1.555.155-2.25.436Zm6.934 9.314h-.934a.75.75 0 0 0-.75.75v.697a6.028 6.028 0 0 0 1.684-1.447ZM6 13.311A6.003 6.003 0 0 1 1.5 7.5h.75a.75.75 0 0 1 .75.75V9a2.25 2.25 0 0 0 2.25 2.25A.75.75 0 0 1 6 12v1.311Z"
      fill={color}
    />
  </svg>
);

export default AllEquipmentIconIcon;
