/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiConfig } from 'src/config';
import { ErrorResponseType } from 'src/types';

const axiosInstance = axios.create({
  baseURL: apiConfig.url,
});

export const axiosV2 = axios.create({
  ...axiosInstance,
  baseURL: apiConfig.v2_url,
});

export class ErrorResponse<D = any> implements AxiosError {
  constructor(
    public name: AxiosError['name'],
    public message: AxiosError['message'],
    public isAxiosError: AxiosError['isAxiosError'],
    public config: AxiosRequestConfig<ErrorResponseType>,
    public toJSON: AxiosError['toJSON'],
    public code?: string,
    public request?: any,
    public response?: AxiosResponse<ErrorResponseType<D>>,
    public stack?: string,
    public cause?: unknown,
  ) {}
}

axiosInstance.interceptors.response.use(
  response => response,
  (error: AxiosError<ErrorResponseType>) => {
    if (error.response?.status === 500) {
      // Capture the error with Sentry
      Sentry.captureException(error);
    }

    if (error.response?.status === 401) {
      // localStorage.removeItem('accessToken');
    }

    return Promise.reject(
      new ErrorResponse(
        error.name,
        error.message,
        error.isAxiosError,
        error.config,
        error.toJSON,
        error.code,
        error.request,
        error.response,
        error.stack,
        error.cause,
      ),
    );
  },
);

if (process.env.NODE_ENV === 'development') {
  axiosInstance.interceptors.request.use(config => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Accept: 'application/json',
      },
      params: {
        ...config.params,
        XDEBUG_SESSION: document.cookie.includes('XDEBUG_SESSION')
          ? 'PHPSTORM'
          : undefined,
      },
    };
  });
}

export default axiosInstance;

let interceptor: number | null = null;
const publicRoutes = [
  '/register',
  '/404',
  '/403',
  '/410',
  '/400',
  '/access-denied',
];

function copyInterceptors(fromInstance: any, toInstance: any) {
  // Copy request interceptors
  fromInstance.interceptors.request.forEach((i: any) => {
    toInstance.interceptors.request.use(i.fulfilled, i.rejected);
  });

  // Copy response interceptors
  fromInstance.interceptors.response.forEach((i: any) => {
    toInstance.interceptors.response.use(i.fulfilled, i.rejected);
  });
}

export const setup = (getToken: (() => Promise<string>) | undefined) => {
  if (getToken) {
    interceptor = axiosInstance.interceptors.request.use(async config => {
      if (!publicRoutes.includes(window.location.pathname)) {
        const token = await getToken();

        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }

      return config;
    });
    copyInterceptors(axiosInstance, axiosV2);
  } else if (interceptor) {
    axios.interceptors.request.eject(interceptor);
  }
};
