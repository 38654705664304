// TODO: type any will be included in the ban-types soon. Please fix all no-explicit-any
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useEffect,
  useReducer,
  FC,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import mixpanel from 'mixpanel-browser';

import { apiConfig } from 'src/config';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import { Cart, Product } from 'src/types';
import { analytic } from 'src/utils/analytic';
import axios from 'src/utils/axios';

interface CartState {
  isOpen: boolean;
  productCount: number;
  cartProduct: Cart;
}

interface CartContextValue extends CartState {
  getProductCount: () => Promise<void>;
  setProductCount: (count: number) => void;
  getCartProducts: () => Promise<void>;
  setCartProducts: (cart: Cart) => void;
  updateProduct: (product: Product) => void;
  addProduct: (
    product: any,
    quantity: any,
    rentMonth: any,
    variant_id: any,
    update?: any,
    budget_category_id?: string | number,
  ) => Promise<void>;
  removeProduct: (product: any) => Promise<void>;
  openCart: (open: any) => void;
  buyTotal: number;
  rentTotal: number;
  setBuyTotal: Dispatch<SetStateAction<number>>;
  setRentTotal: Dispatch<SetStateAction<number>>;
}

interface CartProviderProps {
  children: ReactNode;
}

type ProductCountAction = {
  type: 'SET_PRODUCT_COUNT';
  payload: {
    count: number;
  };
};

type OpenCartAction = {
  type: 'OPEN_CART';
  payload: {
    isOpen: boolean;
  };
};

type CartProductAction = {
  type: 'SET_CART_PRODUCT';
  payload: {
    cart: Cart;
  };
};
type UpdateCartProductAction = {
  type: 'UPDATE_CART_PRODUCT';
  payload: {
    product: Product;
  };
};

type Action =
  | ProductCountAction
  | OpenCartAction
  | CartProductAction
  | UpdateCartProductAction;

const initialCartState: CartState = {
  isOpen: false,
  productCount: 0,
  cartProduct: {
    total_buy: '',
    total_rent: '',
    cart: [],
  },
};

const reducer = (state: CartState, action: Action): CartState => {
  switch (action.type) {
    case 'SET_PRODUCT_COUNT': {
      const { count } = action.payload;

      return {
        ...state,
        productCount: count,
      };
    }

    case 'OPEN_CART': {
      const { isOpen } = action.payload;

      return {
        ...state,
        isOpen,
      };
    }

    case 'SET_CART_PRODUCT': {
      const { cart } = action.payload;

      return {
        ...state,
        cartProduct: cart,
      };
    }

    case 'UPDATE_CART_PRODUCT': {
      const { product } = action.payload;
      const updatedCart = state.cartProduct.cart.map(item => {
        return item.product.id === product.id
          ? {
              ...item,
              product,
            }
          : item;
      });

      return {
        ...state,
        cartProduct: { ...state.cartProduct, cart: updatedCart },
      };
    }

    default: {
      return { ...state };
    }
  }
};

const CartContext = createContext<CartContextValue>({
  ...initialCartState,
  openCart: () => {},
  setProductCount: () => {},
  addProduct: () => Promise.resolve(),
  removeProduct: () => Promise.resolve(),
  getProductCount: () => Promise.resolve(),
  getCartProducts: () => Promise.resolve(),
  setCartProducts: () => {},
  updateProduct: () => {},
  rentTotal: 0,
  buyTotal: 0,
  setRentTotal: () => {},
  setBuyTotal: () => {},
});

export const CartProvider: FC<CartProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCartState);
  const { data: user } = useUserProfile();
  const [buyTotal, setBuyTotal] = useState(0);
  const [rentTotal, setRentTotal] = useState(0);

  const getProductCount = async () => {
    try {
      const response = await axios.get(`${apiConfig.url}/cart-products-count`);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await setProductCount(response.data.data.count);
    } catch (err) {
      console.error(err);
    }
  };

  const getCartProducts = async () => {
    try {
      const cartRes = await axios.get<{ data: Cart }>(
        `${apiConfig.url}/cart-products`,
      );

      const { data } = cartRes.data;

      setBuyTotal(Number(data.total_buy));
      setRentTotal(Number(data.total_rent));

      const segmentCart: any[] = [];

      data.cart.forEach(x => {
        segmentCart.push({
          product_id: x.product.id,
          product_quantity: x.product_quantity,
        });
      });

      analytic.track('Current Cart', {
        cart: segmentCart,
      });

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await setCartProducts(data);
    } catch (err) {
      console.error(err);
    }
  };

  const addProduct = async (
    product: any,
    quantity: any,
    variant_id: any,
    rentMonth: any,
    update = false,
    budget_id?: string | number,
  ) => {
    const data: any = {
      product_id: product.id,
      variant_id,
      update,
    };

    if (quantity) {
      data.product_quantity = quantity;
    }

    if (product.type === 'Rent' && rentMonth) {
      data.rent_month = rentMonth;
    }

    if (budget_id) {
      data.budget_id = budget_id;
    }

    // Segment tracker
    analytic.track('Product Added/Quantity Changed in Cart', {
      product_id: product.id,
      quantity,
    });

    const response = await axios.post(
      `${apiConfig.url}/product/cart/add`,
      data,
    );

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    refreshAvailableBudgets();
    // refreshAvailableBudgets(response.data.data);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await setProductCount(response.data.data.count);
  };

  const refreshAvailableBudgets = () => {
    // if (user?.employee?.available_rent_budget)
    //   user.employee.available_rent_budget = data.available_rent_budget;
    // if (user?.employee?.available_purchase_budget)
    //   user.employee.available_purchase_budget = data.available_purchase_budget;
    // refreshUser(user, true);
  };

  const removeProduct = async (product: any) => {
    try {
      const data = {
        product_id: product.product_id,
        variant_id: product.variant_id,
      };
      const response = await axios.post(
        apiConfig.url + `/product/cart/remove`,
        data,
      );

      // refreshAvailableBudgets(response.data.data);
      refreshAvailableBudgets();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await setProductCount(response.data.data.count);

      // Segment tracker
      analytic.track('Product Removed from Cart', {
        product_id: product.product_id,
      });

      // mixpanel tracker
      try {
        mixpanel.track('remove from cart', {
          product_id: response.config.data.split(':')[1].slice(0, -1),
          event_type: 'page view',
        });
      } catch (error) {
        console.error('mixpanel error', error);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const setProductCount = async (count: number) => {
    dispatch({
      type: 'SET_PRODUCT_COUNT',
      payload: {
        count,
      },
    });
  };

  const openCart = async (open = true) => {
    dispatch({
      type: 'OPEN_CART',
      payload: {
        isOpen: open,
      },
    });
  };

  const setCartProducts = async (cart: Cart) => {
    dispatch({
      type: 'SET_CART_PRODUCT',
      payload: {
        cart,
      },
    });
  };

  const updateProduct = (product: Product) => {
    dispatch({
      type: 'UPDATE_CART_PRODUCT',
      payload: {
        product,
      },
    });
  };

  useEffect(() => {
    const initialise = async () => {
      if (user?.role.name !== 'admin') {
        await getProductCount();
        await getCartProducts();
      }
    };

    if (user) {
      initialise();
    }
  }, [user]);

  return (
    <CartContext.Provider
      value={{
        ...state,
        openCart,
        getProductCount,
        addProduct,
        removeProduct,
        setProductCount,
        getCartProducts,
        setCartProducts,
        updateProduct,
        buyTotal,
        rentTotal,
        setBuyTotal,
        setRentTotal,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
